<template>
	<div class="createParks">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline search-form">
				<el-form-item label="机构类别：">
					<el-select v-model="formInline.discriminationCode" placeholder="请选择">
						<el-option label="全部" value=""></el-option>
						<el-option label="医院" value="H"></el-option>
						<el-option label="乡镇/街道卫生院" value="C"></el-option>
						<el-option label="村卫生室" value="R"></el-option>
						<el-option label="社区卫生中心" value="S"></el-option>
						<el-option label="诊所" value="L"></el-option>
						<el-option label="其他" value="O"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item
					:label="
						formInline.discriminationCode === 'C'
							? '卫生院名称：'
							: formInline.discriminationCode === 'R'
							? '卫生室名称：'
							: formInline.discriminationCode === 'H'
							? '机构名称：'
							: formInline.discriminationCode === 'S'
							? '卫生中心名称：'
							: '诊所名称：'
					"
				>
					<el-autocomplete
						v-model.trim="formInline.name"
						:fetch-suggestions="querySearchAsync"
						clearable
						placeholder="请输入内容"
						@select="handleSelect"
					></el-autocomplete>
				</el-form-item>
				<el-form-item label="市：">
					<el-select v-model="formInline.city" disabled placeholder="请选择" clearable>
						<el-option v-for="(item, index) in []" :key="index" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="区县：">
					<el-select v-model="formInline.county" disabled placeholder="请选择" clearable>
						<el-option v-for="(item, index) in []" :key="index" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="乡镇/街道：" v-if="formInline.discriminationCode && formInline.discriminationCode != 'H'">
					<el-select v-model="formInline.regionCode" placeholder="请选择" clearable @change="changeTown($event)">
						<el-option v-for="(item, index) in townList" :key="index" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item
					label="村："
					v-if="formInline.discriminationCode && formInline.discriminationCode != 'H' && formInline.discriminationCode != 'C'"
				>
					<el-select v-model="formInline.villageCode" placeholder="请选择" clearable>
						<el-option v-for="(item, index) in villageList" :key="index" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
				<el-button type="primary" @click="modifyParkInfo($event, false)" icon="el-icon-plus">新增</el-button>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="tableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:is-show-selection="false"
				:operation-column-width="160"
				:showOperation="true"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<!-- 插槽操作栏固定属性operation -->
				<template #enabled="{ scope }">
					<el-switch inactive-text="否" active-text="是" v-model="scope.row.enabled" @change="changeOpen(scope.row)"> </el-switch>
				</template>
				<template #departmentConfig="{ scope }">
					<el-button @click="departmentConfig(scope.row)" size="mini" type="text"> 科室配置 </el-button>
				</template>
				<template #operation="{ scope }">
					<el-button @click="modifyParkInfo($event, true, scope.row)" size="mini" type="primary"> 查看 </el-button>
					<el-button
						@click="modifyParkInfo($event, false, scope.row)"
						size="mini"
						:type="scope.row.enabled ? 'info' : 'warning'"
						:disabled="scope.row.enabled"
					>
						修改
					</el-button>
				</template>
			</Table>
		</div>
		<createView ref="createView" :isShowDetail="isShowDetail" @getList="getList" />
	</div>
</template>

<script>
export default {
	name: 'countyMedicalManager',
	components: {
		Table: () => import('@/components/Table/table'),
		createView: () => import('./newDialog.vue'),
	},
	data() {
		return {
			formInline: {
				name: '',
				city: '',
				county: '',
				regionCode: '',
				villageCode: '',
				levelDictionaryItemId: null,
				discriminationCode: '',
			},
			levelDictionaryItemList: [], //机构等级
			// institutionTypeDictionaryItemList: [], //机构类别
			townList: [], //乡镇/街道
			villageList: [], //村
			loading: false,
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			tableHead: [
				{
					label: '机构名称',
					prop: 'name',
					width: 110,
					formatter: (row) => {
						return row.name || '-';
					},
				},
				{
					label: '机构代码',
					prop: 'organizationCode',
					width: 110,
					formatter: (row) => {
						return row.organizationCode || '-';
					},
				},
				{
					label: '机构类别',
					width: 100,
					prop: 'discriminationCode',
					formatter: (row) => {
						return row.discriminationCode == 'H'
							? '医院'
							: row.discriminationCode == 'C'
							? '乡镇/街道卫生院'
							: row.discriminationCode == 'R'
							? '村卫生室'
							: row.discriminationCode == 'S'
							? '社区卫生中心'
							: row.discriminationCode == 'L'
							? '诊所'
							: '-';
					},
				},
				{
					label: '机构级别',
					prop: 'institutionTypeDictionaryItem',
					formatter: (row) => {
						return row.institutionTypeDictionaryItem?.title || '-';
					},
				},
				{
					label: '主管单位',
					prop: 'administrativeAuthorityDictionaryItem',
					formatter: (row) => {
						return row.administrativeAuthorityDictionaryItem?.title || '-';
					},
				},
				{
					label: '地址',
					prop: 'address',
				},
				{
					label: '床位数量',
					prop: 'bedCount',
					formatter: (row) => {
						return row.bedCount || '-';
					},
				},
				{
					label: '联系人',
					prop: 'principalName',
					formatter: (row) => {
						return row.principalName || '-';
					},
				},
				{
					label: '联系电话',
					prop: 'phoneNumber',
					formatter: (row) => {
						return row.phoneNumber || '-';
					},
				},
				{
					label: '科室管理',
					prop: 'departmentConfig',
				},
				{
					label: '是否启用',
					prop: 'enabled',
					width: 110,
				},
			],
			isShowDetail: false,
			filterLoading: false,
			options: [],
			link: '',
			checkTab: 1,
		};
	},
	props: {
		type: {
			type: String,
			default: '',
		},
		tableHeadTown: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	mounted() {
		this.getList();
		let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
		if (userInfo && userInfo.chinaRegionDtoList && userInfo.chinaRegionDtoList.length > 0) {
			this.formInline.city = userInfo.chinaRegionDtoList[1].title;
			this.formInline.county = userInfo.chinaRegionDtoList[2].title;
		}
		this.getInstitList();
		this.getTownList();
	},
	methods: {
		// 获取乡镇/街道
		getTownList() {
			this.$http
				.get(this.api['ChinaRegions#index'].href, { params: { code: JSON.parse(sessionStorage.getItem('userInfo')).chinaRegionDtoList[2].code } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.townList = res.data.collection || [];
						this.townList.unshift({
							title: '全部',
							code: '',
						});
					}
				})
				.catch((e) => {});
		},

		changeTab(val) {
			this.checkTab = val;
			this.formInline.name = '';
			this.queryData.current = 1;
			this.getList();
		},
		changeTown(code) {
			this.formInline.villageCode = null;
			this.villageList = [];
			if (!code || code == '') return;
			this.$http
				.get(this.api['ChinaRegions#index'].href, { params: { code } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.villageList = res.data.collection || [];
						this.villageList.unshift({
							title: '全部',
							code: '',
						});
					}
				})
				.catch((e) => {});
		},
		// 搜索建议
		querySearchAsync(queryString, cb) {
			queryString ? this.getList('filter') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(this.options);
			}, 1000 * Math.random());
		},
		handleSelect(subInput) {
			this.formInline.name = subInput.name;
		},
		onSearch() {
			this.queryData.current = 1;
			this.getList();
		},
		changeOpen(row) {
			this.$http
				.patch(row._links.patch.href, { enabled: row.enabled })
				.then((res) => {
					if (res.data && res.data.success) {
						this.$message.success('操作成功');
						this.getList();
					}
				})
				.catch((e) => {});
		},
		// 获取机构等级list
		getInstitList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'INSTITUTIONAL_LEVEL', size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.levelDictionaryItemList = res.data.collection || [];
						this.levelDictionaryItemList.unshift({
							id: null,
							title: '全部',
						});
					}
				})
				.catch((e) => {});
		},
		// 获取机构类别
		// getInstitTypeList() {
		// 	this.$http
		// 		.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'INSTITUTIONAL_TYPE', size: 9999, current: 1 } })
		// 		.then((res) => {
		// 			if (res.data && res.data.success) {
		// 				this.institutionTypeDictionaryItemList = res.data.collection || [];
		// 				this.institutionTypeDictionaryItemList.unshift({
		// 					id: null,
		// 					title: '全部',
		// 				});
		// 			}
		// 		})
		// 		.catch((e) => {});
		// },
		//获取列表
		getList(filterType = '') {
			filterType == 'filter' ? (this.filterLoading = true) : (this.loading = true);
			let data = {
				...this.formInline,
				...this.queryData,
			};
			this.$http
				.get(this.api['MedicalOrganizations#index'].href, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						if (filterType == 'filter') {
							this.filterLoading = false;
							this.options = res.data.collection.map((item) => {
								return {
									...item,
									value: item.name,
								};
							});
						} else {
							this.tableData = res.data.collection || [];
							this.total = res.data.pagination.totalItems || 0;
							this.loading = false;
							this.link = res.data._links.create.href || '';
						}
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		modifyParkInfo(event, isShowDetail, row) {
			let data = {
				row: row || null,
				link: row ? row._links.update.href : this.link,
			};
			this.$refs.createView.init(data);
			this.isShowDetail = isShowDetail;
		},
		departmentConfig(row) {
			this.$router.push({
				path: '/institutionManager/departmentManager',
				query: {
					id: row.id,
				},
			});
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .search-form {
	.el-select {
		width: 120px;
		.el-input {
			width: 120px;
			.el-input__inner {
				width: 120px;
			}
		}
	}
	.el-input {
		width: 160px;
	}
}

.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
.seeMore {
	color: #0d99ff;
}
</style>
